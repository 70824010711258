import store from "@/store/store";

function getErrorMessage(e) {
    let data = e.response?.data;

    return data?.message ?? data ?? e;
}

function handleError(e) {
    if(process.env.NODE_ENV === 'development') {
        // Turn this on for debugging in development only.
        // eslint-disable-next-line no-console
        console.log(e);
    }

    if(isValidationErrors(e)) {
        let messages = getValidationMessages(e);
        messages.forEach(message => store.dispatch("alert/error", message));
        return;
    }

    store.dispatch("alert/error", getErrorMessage(e));
}

function isValidationErrors(e) {
    return !!(e.response?.data?.errors);
}

function getValidationMessages(e) {
    return Object
        .entries(e.response.data.errors)
        .map(([, messages]) => messages)
        .flat();
}

export default {
    getErrorMessage,
    handleError,
};

export {
    getErrorMessage,
    handleError
}