import { byId } from '@/services/arrayUtility'
import { hasProperty, isEmpty } from '@/services/objectUtility';
import { subscribe } from "@/services/actionHub"
import queries from "@/features/tasks/services/queries";
import localSearch from "@/features/tasks/services/localSearch";

function get(itemsById, id) {
    if(id == null) {
        return null;
    }

    if(typeof id !== "string") {
        id = id.toString();
    }

    return itemsById[id];
}

function hasKey(itemsById, key) {
    return hasProperty(itemsById, key.toString())
}

function getDefaultState() {
    return {
        filter: {},
        filterState: "",
        items: [],
        allItems: [],
        itemsById: {},
        isLoaded: false,
        loading: false
    };
}

function buildFilterModel(getters, state) {
    return {
        buckets: getters.filteredBuckets,
        personIds: state.filter.personIds,
        clientIds: state.filter.clientIds,
        projectIds: state.filter.projectIds,
        taskTypes: getters.filteredTaskTypes,
        tagIds: state.filter.tagIds
    };
}

const state = getDefaultState;

const actions = {
    async load({ commit, dispatch }) {
        // Listen to server notifications when a task item has been added, updated or deleted.
        const subscription = subscribe(
            messages => dispatch("receiveMessages", messages),
            [ "taskItem" ]);

        commit("subscription", subscription);
        commit("isLoaded", true);
    },

    updateFilter({ commit, dispatch }, filter) {
        commit("filter", filter);
        dispatch("refresh");
    },

    async refresh({ commit, getters, state }) {
        if(isEmpty(state.filter)) {
            return;
        }

        let filter = buildFilterModel(getters, state)
        // We don't want to hit the server for client side filtering (search text).
        let filterState = JSON.stringify(filter);
        if(filterState == state.filterState) {
            return;
        }
        filter.projectIds = null;
        filter.clientIds = null;
        filterState = JSON.stringify(filter);
        commit("filterState", filterState);

        commit("setLoading", true);

        try {
            const items = await queries.tasks(filter);
            commit("allItems", items);

            const { clientIds, projectIds } = state.filter;

            const filteredItems = items.filter(item => {
                const clientIdMatch = !clientIds.length || clientIds.includes(item.clientId);
                const projectIdMatch = !projectIds.length || projectIds.includes(item.projectId);
                return clientIdMatch && projectIdMatch;
            });

            commit("items", filteredItems);
        } finally {
            commit("setLoading", false);
        }
    },

    async receiveMessages({ commit, getters, state }, messages) {
        if(isEmpty(state.filter)) {
            return;
        }

        messages
            .filter(t => t.action == "delete" && hasKey(state.itemsById, t.id))
            .forEach(t => commit("deleteItem", t.id));

        const ids = messages
            .filter(t => t.action == "add" || t.action == "update")
            .map(t => t.id);

        if(!ids.length) {
            return;
        }

        // To get the relevant task items, identify those which match the current filter.
        const filter = buildFilterModel(getters, state)
        const items = await queries.tasks(filter, ids);
        const idsInFilter = new Set(items.map(t => t.id));

        // If a current item has fallen out of the filter, then remove it.
        ids
            .filter(id => !idsInFilter.has(id) && hasKey(state.itemsById, id))
            .forEach(id => commit("deleteItem", id));

        // Otherwise add or update it.
        items.forEach(item => commit("updateItem", item));

        if(items.length) {
            commit("sort");
        }
    },

    clear({ commit, state }) {
        state.subscription.unsubscribe();
        commit("clear");
    }
};

const getters = {
    getTask: state => id => get(state.itemsById, id),

    filteredBuckets: (state, getters) => isEmpty(state.filter.buckets) ?
        getters.defaultBuckets :
        state.filter.buckets,

    filteredTaskTypes: state => isEmpty(state.filter.taskTypes) ?
        null :
        state.filter.taskTypes,

    filteredItems: state => localSearch(state.items, state.filter.search),

    defaultBuckets: () => [
        1, // Estimating
        2, // Approved
        3, // In Progress
        4 // Review
    ]
};

const mutations = {
    filter(state, filter) {
        state.filter = filter;
    },
    filterState(state, filterState) {
        state.filterState = filterState;
    },
    items(state, items) {
        state.items = items;
        state.itemsById = byId(items);
    },
    allItems(state, items) {
        state.allItems = items;
    },
    updateItem(state, itemUpdate) {
        const item = state.itemsById[itemUpdate.id];
        if(item == null) {
            state.items.push(itemUpdate);
            state.itemsById[itemUpdate.id] = itemUpdate;
        }
        else {
            Object.assign(item, itemUpdate);
        }
    },
    deleteItem(state, id) {
        const item = state.itemsById[id];
        if(item == null) {
            return;
        }
        const index = state.items.indexOf(item);
        if(index >= 0) {
            state.items.splice(index, 1);
        }
        delete state.itemsById[id];
    },
    sort(state) {
        // TODO: This sorts the array in place. Ensure this is the correct approach.
        const items = state.items;
        items.sort((a, b) => a.sortOrder - b.sortOrder);
        state.items = items;
    },
    subscription(state, subscription) {
        state.subscription = subscription;
    },
    isLoaded(state, isLoaded) {
        state.isLoaded = isLoaded;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    },
    setLoading(state, isLoading) {
        state.loading = isLoading;
    },
};

export const task = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
