<template>
    <v-form
        ref="form"
        v-model="isValid"
        @submit.prevent="save()">
        <v-card :dark="darkMode">
            <v-card-title>
                <span class="headline">{{ isEdit ? "Edit" : "Add" }} {{ entity.singleTitle }}</span>
            </v-card-title>

            <v-card-text>
                <component
                    :is="getComponent(field)"
                    v-for="(field, index) in fields"
                    :key="field.value"
                    v-model="item[field.key]"
                    :field="field"
                    :autofocus="index == 0"
                    :fixed-values="fixedValues"
                    validate-on-blur
                    @save="save" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="pa-4">
                <app-button
                    v-if="canDelete"
                    color="error"
                    text
                    @click="deleteItem">
                    Delete
                </app-button>
                <v-spacer />
                <app-button
                    v-if="canCancel"
                    color="tertiary"
                    text
                    @click="$emit('cancel')">
                    Cancel
                </app-button>
                <app-button
                    v-if="canSave"
                    type="submit"
                    text
                    :disabled="isSaving">
                    Save
                </app-button>
            </v-card-actions>

            <v-progress-linear v-if="isSaving" indeterminate color="blue" />
        </v-card>
    </v-form>
</template>

<script>
import formMixin from "@/features/schemas/mixins/formMixin";

export default {
    mixins:[ formMixin ],
    types: [
        "default"
    ],
}
</script>
