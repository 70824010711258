<template>
    <app-dialog :value="!!value" :max-width="maxWidth" @keydown.esc="$emit('input', null)">
        <component
            :is="formComponent"
            :key="formKey"
            :entity-key="entityKey"
            :value="value"
            v-bind="$attrs"
            v-on="$listeners"
            @cancel="$emit('input', null)" />
    </app-dialog>
</template>

<script>
import schemaMixin from "@/features/schemas/mixins/schemaMixin";
import forms from "@/features/schemas/forms";

export default {
    mixins: [schemaMixin],
    props: {
        value: {
            type: Object,
            default: null
        },
        form: {
            type: Function,
            default: null
        },
        maxWidth: {
            type: String,
            default: "500px"
        }
    },
    data() {
        return {
            formKey: 0
        }
    },
    computed: {
        formComponent() {
            return this.form ?? forms.getComponent(this.entity);
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.formKey++;
                }
            }
        }
    }
}
</script>
