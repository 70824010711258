<template>
    <div class="app-search">
        <app-text-field
            v-if="!isEditing"
            key="displayField"
            class="app-search-item-display"
            v-bind="$attrs"
            :value="display"
            :label="label"
            :rules="rules"
            :hide-details="hideDetails"
            autocomplete="off"
            :autofocus="false"
            :append-icon="closeIcon"
            @click:append="remove"
            @focus="edit" />

        <app-text-field
            v-if="isEditing"
            ref="editField"
            key="editField"
            v-model="searchText"
            v-bind="$attrs"
            :rules="rules"
            :label="label"
            :hide-details="hideDetails"
            placeholder="Click to select"
            autocomplete="off"
            autofocus
            @blur="clear"
            @keydown="onKeyDown" />

        <v-menu
            :value="menuVisible"
            :close-on-click="false"
            :close-on-content-click="false"
            :disable-keys="true"
            :open-on-click="false"
            :max-height="304"
            :top="isMenuAbove"
            :dark="darkMode"
            :nudge-top="nudgeAmount"
            @input="updateisMenuAbove()">
            <template #activator="{ on }">
                <div ref="activator" :style="activatorStyle" @null="on" />
            </template>
            <v-list class="app-search-results">
                <v-list-item
                    v-for="(item, index) in items"
                    :key="item.id"
                    link
                    :class="itemClass(index)"
                    @mousedown.prevent="onItemClick(item)">
                    <v-list-item-title>
                        {{ item.label }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { isNullOrWhiteSpace, trim } from "@/services/stringUtility";
import { buildSelectQuery } from "@/features/schemas/services/searcher"
import { getTableData } from "@/features/schemas/services/tableService";
import { getEntity } from "@/features/schemas/services/schemaProvider";
import { get } from "@/features/schemas/services/schemaApi";
import { getLabel } from "@/features/schemas/services/labeller";
import { debounce } from "@/services/debounce";

export default {
    props: {
        entityKey: {
            type: String,
            default: null,
            required: true
        },
        value: {
            type: Number,
            default: null
        },
        rules: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: () => "Search"
        },
        filter: {
            type: Object,
            default: null,
        },
        searchOptions: {
            type: Object,
            default: null,
        },
        searchWhenBlank: {
            type: Boolean,
            default: true
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        preserveSearch: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            isEditing: false,
            searchText: null,
            results: null,
            selectedIndex: -1,
            selectedItem: null,
            showMenu: false,
            isMenuAbove: false,
            nudgeAmount: 0,
        }
    },
    computed: {
        items() {
            return this.results?.items ?? [];
        },
        display() {
            if (this.selectedItem == null) {
                if (this.preserveSearch) {
                    return this.searchText;
                }
                return "";
            }

            return this.selectedItem.label;
        },
        activatorStyle() {
            if(this.hideDetails) {
                return null;
            }
            return {
                marginTop: "-26px",
                height: "26px"
            };
        },
        closeIcon() {
            return this.value && this.clearable ? "mdi-close" : null;
        },
        searchIsBlank() {
            return isNullOrWhiteSpace(trim(this.searchText, "\""));
        },
        menuVisible() {
            return this.showMenu && this.items && this.items.length;
        },
        menuListHeight() {
            // Each item is 50px, -10px to account for padding
            const itemListHeight = this.items.length * 50 - 10;
            return itemListHeight > 304 ? 304 : itemListHeight;
        },
    },
    watch: {
        searchText: debounce(function () {
            return this.search();
        }, 250),
        selectedItem(selectedItem) {
            if(this.value !== selectedItem?.id) {
                this.$emit("input", selectedItem?.id);
                this.$emit("item", selectedItem)
            }
        },
        value: {
            immediate: true,
            async handler(value) {
                if (!value) {
                    this.searchText = null;
                    this.selectedItem = null;
                    this.showMenu = false;
                    return;
                }

                if (this.selectedItem?.id !== value) {
                    let item = await get(this.entityKey, value);
                    item.label = getLabel(this.entityKey, item);
                    this.selectedItem = item;
                }
            }
        }
    },
    methods: {
        edit() {
            this.isEditing = true;
            this.showMenu = true;
            this.search();
        },
        async search() {
            this.$emit("search", this.searchText)
            if (!this.searchWhenBlank && this.searchIsBlank) {
                this.results = null;
                return;
            }

            let searchText = this.searchText;

            let options = {
                filter: this.filter,
                sortBy: this.searchOptions?.sortBy,
                direction: this.searchOptions?.direction,
                take: this.searchOptions?.take,
            };

            let model = buildSelectQuery(this.entityKey, searchText, options);

            let results = await getTableData(this.entityKey, model);

            results.items.forEach(item => item.label = getLabel(this.entityKey, item));
            results.headers.push({
                value: "label",
                text: getEntity(this.entityKey).singleTitle
            });

            // Avoid showing previous search if results arrive out of order.
            if (searchText === this.searchText) {
                this.results = results;
            }
        },
        onKeyDown(e) {
            let handledKeys = ["ArrowDown", "ArrowUp", "Enter", "Tab"];

            if (handledKeys.includes(e.key)) {
                let handler = this[`on${e.key}`];
                handler(e);
            }
        },
        onArrowDown(e) {
            let index = this.selectedIndex + 1;
            if (index < this.items.length) {
                this.selectedIndex = index;
            }
            e.preventDefault();
        },
        onArrowUp(e) {
            let index = this.selectedIndex - 1;
            if (index > -1) {
                this.selectedIndex = index;
            }
            e.preventDefault();
        },
        onEnter(e) {
            if (this.selectedIndex > -1) {
                this.selectedItem = this.items[this.selectedIndex];
                this.clear();
            }
            e.preventDefault();
        },
        onTab() {
            if (this.selectedIndex > -1) {
                this.selectedItem = this.items[this.selectedIndex];
                this.clear();
            }
        },
        onItemClick(item) {
            this.selectedItem = item;
            this.clear();
        },
        clear() {
            this.results = null;
            this.selectedIndex = -1;
            this.isEditing = false;
            this.showMenu = false;
        },
        remove() {
            this.selectedItem = null;
            this.searchText = null;
            this.clear();
        },
        itemClass(index) {
            if (index == this.selectedIndex) {
                return "app-search-selected"
            }
        },
        // Put the menu above the input box if not enough space in the viewport to fit it.
        updateisMenuAbove() {
            let inputBox = this.$refs["activator"].getBoundingClientRect();
            // Calculate the distance from the bottom of the viewport

            const distanceFromBottom = window.innerHeight - inputBox.bottom;

            if (distanceFromBottom < this.menuListHeight) {
                this.isMenuAbove = true;
                this.nudgeAmount = 80;
            } else {
                this.isMenuAbove = false;
                this.nudgeAmount = 0;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "@/styles/theme.scss";

    .app-search-selected {
        color: $color-primary;
        background-color: rgba($color-primary, 0.16);
    }

    .app-search-results > * {
        cursor: pointer;
    }
</style>
