<template>
    <div ref="topDiv">
        <v-textarea
            v-if="editingLocal"
            ref="markdownEditor"
            :key="editKey"
            :value="value"
            rows="1"
            autofocus
            :dark="darkMode"
            dense
            no-resize
            outlined
            hide-details
            class="markdown-editor pa-0"
            :placeholder="placeholder"
            v-bind="$attrs"
            v-on="$listeners"
            @blur="onBlur"
            @input="onInput($event)" />

        <div v-if="!editingLocal" :class="markdownAreaClasses" @click="onClick">
            <!-- This is ok because we are using the dompurify package to sanitize the HTML. -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="markdown" v-html="markdown" />
            <span v-if="!hasValue" class="markdown-placeholder">{{ placeholder }}</span>
        </div>
    </div>
</template>

<script>
import domPurify from "dompurify";
import marked from "marked";
import { isNullOrWhiteSpace } from "@/services/stringUtility"

export default {
    props:{
        value: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: "Enter markdown..."
        },
        editMode: {
            type: String,
            default: "auto",
            vaidator(value) {
                return ["auto", "manual"].includes(value);
            }
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editKey: 0,
            editingLocal: false
        };
    },
    computed: {
        markdown() {
            return marked(this.value ?? "", { sanitizer: domPurify.sanitize });
        },
        markdownAreaClasses() {
            let classes = [ "markdown-area" ];

            if(this.editMode === "auto") {
                classes.push("markdown-area-clickable");
            }

            return classes;
        },
        hasValue() {
            return !isNullOrWhiteSpace(this.value);
        },
    },
    watch: {
        editing: {
            immediate: true,
            handler(value) {
                if(value) {
                    this.edit();
                }
                else {
                    this.stopEditing();
                }
            }
        }
    },
    methods: {
        edit() {
            // the autofocus attribute only works once. To get around this, we create a new DOM
            // element each time by changing the :key attribute.
            this.editKey++;
            this.editingLocal = true;
            this.$emit('update:editing', true);

            this.doAndPreserveScroll(this.$refs.topDiv, () => {
                this.$nextTick(() => {
                    let textarea = this.getTextarea();
                    textarea.select(); // This prevents the cursor forcing us to scroll to the end.
                    updateHeight(textarea);
                });
            });
        },
        stopEditing() {
            this.editingLocal = false;
            this.$emit('update:editing', false);
        },
        onClick() {
            if(this.editMode === "auto") {
                this.edit();
            }
        },
        onBlur() {
            this.$emit('blur')
            if(this.editMode === "auto") {
                this.stopEditing();
            }
        },
        onInput(event) {
            let textarea = this.getTextarea();

            // Vuetify's auto-grow attribute is buggy, causing the scroll bar to jump around.
            // This behaviour is re-implemented here, with a fix for the bug.
            this.doAndPreserveScroll(textarea, () => updateHeight(textarea));

            this.$emit('input', event);
        },
        getTextarea() {
            let editor = this.$refs.markdownEditor;
            return editor?.$refs.input;
        },
        doAndPreserveScroll(element, action) {
            let scrolledElement = getScrolledAncestor(element);
            let scrollTop = scrolledElement?.scrollTop;

            action();

            if(scrolledElement != null) {
                this.$nextTick(() => scrolledElement.scrollTop = scrollTop);
            }
        }
    }
};

function updateHeight(element) {
    // Use the scroll height of the element to determine the height it should be.
    element.setAttribute("style", "height: 0;");
    element.setAttribute("style", `height: ${element.scrollHeight}px;`);
}

function getScrolledAncestor(element) {
    if (element == null) {
        return null;
    }
    if (element.scrollTop > 0) {
        return element;
    }
    return getScrolledAncestor(element.parentNode);
}

</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';
#app.dark .markdown-area {
    background-color: var(--color-background-darken-1);
    color: var(--color-text-dark);
}
.markdown-area {
    padding: 9px 12px 9px;
    background-color: $color-grey-lightest;
    border-radius: 4px;
}

.markdown-area-clickable {
    cursor: pointer;
}

.markdown::v-deep {
    * {
        max-width: 100%;
    }

    > * {
        margin-bottom: 10px;
        line-height: 1.1rem;
    }

    > *:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    blockquote {
        padding: 16px 0 16px 24px;
        font-size: 18px;
        font-weight: 300;
        border-left: 5px solid $color-grey-light;

        p {
            margin-bottom: 0;
        }
    }
}

.markdown-editor::v-deep textarea {
    height: 0;
    margin-top: 0 !important;
    padding: 9px 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.0071428571em;
    overflow-y: hidden;
}
</style>
