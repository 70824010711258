<template>
    <v-app :class="{ dark: darkMode }">
        <template v-if="isLoaded">
            <the-alert />
            <the-app-bar v-if="isLoggedIn" />
            <v-main class="main">
                <router-view class="router-container" />
            </v-main>
        </template>
        <template v-else>
            <div class="loading d-flex align-center justify-center flex-grow-1">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate
                    color="tertiary" />
            </div>
        </template>
    </v-app>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    components: {
        theAlert: () => import("@/components/TheAlert"),
        theAppBar: () => import("@/components/TheAppBar")
    },

    computed: {
        ...mapState({
            isLoggedIn: s => s.authentication.isLoggedIn,
            user: s => s.authentication.user,
        }),

        ...mapGetters({
            isLoaded: "load/isLoaded"
        })
    },

    async mounted() {
        this.onStartup();
    },


    methods: {
        ...mapActions({
            onStartup: "load/onStartup"
        })
    }
};
</script>

<style lang="scss">
    @import "styles/theme.scss";
    @import "styles/layout.scss";
    @import "styles/components.scss";
    @import "styles/utils.scss";

    #app, .loading, .router-container {
        background-color: var(--color-background-light);
    }

    #app.dark {
        background-color: var(--color-background-dark);
        .loading,
        .router-container {
            background-color: var(--color-background-dark);
        }
        .text-white {
            color: var(--color-text-dark);
        }
    }
</style>

<style lang="scss" scoped>
    // v-main dynamically calculates some top padding after the page displays. Here we are
    // hardcoding the results of this calculation. This fixes issues with apex charts, where it
    // calculates the height of the chart before the v-main padding is determined. This also
    // prevents the whole page popping down by 48 pixels.
    .main {
        padding-top: 48px !important;
    }
</style>
