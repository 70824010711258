import api from '@/services/apiService'
import { lookup, byId } from '@/services/arrayUtility'
import { connectToActionHub, closeActionHubConnection, subscribe } from "@/services/actionHub"

function get(itemsById, id) {
    if(id == null) {
        return null;
    }

    if(typeof id !== "string") {
        id = id.toString();
    }

    return itemsById[id];
}

function getDefaultState() {
    return {
        clients: [],
        people: [],
        projects: [],
        tags: [],

        clientsById: {},
        peopleById: {},
        projectsById: {},
        projectsByClientId: {},
        tagsById: {},

        connection: null,
        subscription: null,
        isLoaded: false
    };
}

const refreshActionsByEntityKey = {
    client: "refreshClients",
    person: "refreshPeople",
    project: "refreshProjects",
    tag: "refreshTags",
}

const refreshEntityKeys = Object.keys(refreshActionsByEntityKey);
const refreshActions = Object.values(refreshActionsByEntityKey);

const state = getDefaultState;

const actions = {
    async load({ commit, dispatch }) {
        const promises = refreshActions.map(action => dispatch(action));
        await Promise.all(promises);

        const connection = await connectToActionHub();
        const subscription = subscribe(
            messages => dispatch("receiveMessages", messages),
            refreshEntityKeys);

        commit("subscription", subscription);
        commit("connection", connection);
        commit("isLoaded", true);
    },

    async refreshClients({ commit }) {
        const clients = await api.list("clients", { sortBy: "name" });
        commit("clients", clients);
    },

    async refreshPeople({ commit }) {
        const people = await api.list("people", { sortBy: "name" });
        commit("people", people);
    },

    async refreshProjects({ commit }) {
        const projects = await api.list("projects", { sortBy: "name" });
        commit("projects", projects);
    },

    async refreshTags({ commit }) {
        const tags = await api.list("tags", { sortBy: "name" });
        commit("tags", tags);
    },

    async receiveMessages({ dispatch }, messages) {
        // Get the unique entity keys from the received messages and then dispatch any relevant
        // actions to refresh the data.
        const entityKeys = new Set(messages.map(m => m.entityKey));

        const promises = refreshEntityKeys
            .filter(entityKey => entityKeys.has(entityKey))
            .map(entityKey => dispatch(refreshActionsByEntityKey[entityKey]));

        await Promise.all(promises);
    },

    clear({ commit, state }) {
        state.subscription.unsubscribe();
        closeActionHubConnection(state.connection);
        commit("clear");
    }
};

const getters = {
    getClient: (state) => (id) => get(state.clientsById, id),
    getClientByProjectId: (state) => (id) => {
        let project = get(state.projectsById, id);
        return get(state.clientsById, project?.clientId);
    },
    getPerson: (state) => (id) => get(state.peopleById, id),
    getProject: (state) => (id) => get(state.projectsById, id),
    getProjectsForClient: (state) => (id) => get(state.projectsByClientId, id),
    getTag: (state) => (id) => get(state.tagsById, id),
};

const mutations = {
    clients(state, clients) {
        state.clients = clients;
        state.clientsById = byId(clients);
    },
    people(state, people) {
        state.people = people;
        state.peopleById = byId(people);
    },
    projects(state, projects) {
        state.projects = projects;
        state.projectsById = byId(projects);
        state.projectsByClientId = lookup(projects, p => p.clientId);
    },
    tags(state, tags) {
        state.tags = tags;
        state.tagsById = byId(tags);
    },
    connection(state, connection) {
        state.connection = connection;
    },
    subscription(state, subscription) {
        state.subscription = subscription;
    },
    isLoaded(state, value) {
        state.isLoaded = value;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    }
};

export const data = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
