<template>
    <div class="d-flex flex-column">
        <div class="d-flex flex-wrap">
            <app-button
                v-for="(item, i) in items"
                :key="i"
                class="mr-4 mb-2"
                :outlined="item !== selectedItem"
                @click="selectTab(item)">
                {{ getTitle(item) }}
            </app-button>
        </div>

        <component
            :is="getTabComponent"
            v-if="selectedItem"
            :item="selectedItem"
            :entity-key="entityKey"
            :item-id="itemId" />
    </div>
</template>

<script>
import { getDependants } from "@/features/schemas/services/schemaProvider";
import schemaMixin from '@/features/schemas/mixins/schemaMixin';
import tabs from "@/features/schemas/tabs";
import {canList} from "@/features/schemas/services/schemaApi";

export default {
    mixins: [schemaMixin],
    props: {
        itemId: {
            type: [Number, String],
            default: null
        },
        tabs: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selectedItem: null
        }
    },
    computed: {
        items() {
            const dependants = getDependants(this.entityKey)
                .filter(e => canList(e.entity.key));

            if (!this.tabs?.length) {
                return dependants;
            }

            return dependants.sort((a, b) =>
                this.tabs.indexOf(a.entity.key) - this.tabs.indexOf(b.entity.key)
            );
        },
        getTabComponent() {
            return tabs.getComponent(this.selectedItem?.entity);
        }
    },
    watch: {
        items: {
            immediate: true,
            handler() {
                this.updateSelectedTab(this.$route.query.tab);
            }
        },
        '$route.query.tab': {
            immediate: true,
            handler(newTab) {
                this.updateSelectedTab(newTab);
            }
        }
    },
    methods: {
        updateSelectedTab(tabKey) {
            if (!this.items.length) {
                return;
            }

            const selectedItem =
                this.items.find(item => item.entity.key === tabKey)
                || this.items[0];

            this.selectTab(selectedItem, false);
        },
        selectTab(item, updateURL = true) {
            this.selectedItem = item;

            if (updateURL && this.$route.query.tab !== item.entity.key) {
                this.updateTabInURL(item.entity.key);
            }
        },
        updateTabInURL(tabKey) {
            this.$router.push({ query: { ...this.$route.query, tab: tabKey } });
        },
        getTitle(item) {
            return item.field.title === this.entity.singleTitle
                ? item.entity.pluralTitle
                : `${item.entity.pluralTitle} (${item.field.title})`;
        }
    }
}
</script>
