<template>
    <v-btn
        fab
        dark
        small
        :color="color"
        :disabled="disabled"
        :loading="loading"
        v-bind="$attrs"
        v-on="$listeners">
        <v-icon>{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "primary"
        },
        icon: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>
