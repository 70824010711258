import axios from "axios";

function getDefaultState() {
    return {
        schema: null,
        isLoaded: false
    };
}

function trimTrailingQuestionMark(type) {
    return type.replace(/\?$/, "")
}

function camel(key) {
    return key[0].toLowerCase() + key.slice(1);
}

function getEntity(state, entityKey) {
    entityKey = camel(entityKey);
    return state.schema.entities[entityKey] ?? (state.schema.reportEntities[entityKey] ?? null);
}

const state = getDefaultState;

const getters = {
    schema: state => state.schema,
    enums: state => state.schema.enums,
    entities: state => state.schema.entities,
    getEnum: state => (enumKey) => {
        enumKey = trimTrailingQuestionMark(camel(enumKey));
        return state.schema.enums[enumKey] ?? null;
    },
    getEntity: state => (entityKey) => getEntity(state, entityKey),
    getQueryField: state => (entityKey, fieldKey) => {
        entityKey = camel(entityKey);
        fieldKey = camel(fieldKey);
        return getEntity(state, entityKey).queryFields[fieldKey];
    }
};

const actions = {
    async load({ commit }) {
        let schema = (await axios.get("/api/Schema")).data;
        commit("schema", schema);

        commit("loaded");
    },
    clear({commit}) {
        commit("clear");
    }
};

const mutations = {
    schema(state, schema) {
        state.schema = schema;
    },
    loaded(state) {
        state.isLoaded = true;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    }
};

export const schema = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
