<template>
    <div>
        <span>{{ displayValue }}</span>
    </div>
</template>

<script>
import { camelToTitleCase } from '@/services/stringUtility';

export default {
    types: [
        "list`1",
        "array",
        "iEnumerable`1"
    ],
    props: {
        value: {
            type: [Array],
            default: null,
        }
    },
    computed: {
        displayValue() {
            if (!this.value || this.value.length === 0) {
                return '';
            }
            return this.value.map(camelToTitleCase).join(', ');
        }
    }
};
</script>
