<template>
    <div class="d-flex flex-column schema-table">
        <div v-if="!tableOnly" class="d-flex align-center mb-5">
            <v-text-field
                v-model="searchText"
                class="no-print"
                label="Search"
                hide-details
                dense
                :dark="darkMode"
                color="primary"
                outlined
                prepend-inner-icon="mdi-magnify" />

            <slot name="add-button">
                <app-icon-button
                    v-if="canAdd"
                    class="ml-4 no-print"
                    color="primary"
                    icon="mdi-plus"
                    @click="add" />
            </slot>

            <schema-excel-export-button
                v-if="!disableExport"
                v-bind="$attrs"
                class="ml-4 no-print"
                :entity-key="entityKey"
                :list-request="listRequest" />
        </div>

        <v-card :dark="darkMode" :class="cardClasses" :elevation="outlined ? 0 : 4">
            <component
                :is="tableComponent"
                :key="entityKey"
                ref="schemaDataTable"
                class="flex-grow-1"
                v-bind="$attrs"
                :entity-key="entityKey"
                :search-text="searchText"
                :readonly="readonly"
                :has-item-page="hasItemPage"
                @list-request="setListRequest"
                @edit="edit">
                <template #extra-actions="{ item }">
                    <slot name="extra-actions" :item="item" />
                </template>
            </component>
        </v-card>

        <schema-form-dialog
            :entity-key="entityKey"
            :value="editedItem"
            :form="form"
            v-bind="$attrs"
            @input="onItemSaved" />
    </div>
</template>

<script>
import tableMixin from "@/features/schemas/mixins/tableMixin";

export default {
    mixins:[tableMixin],
    types: [
        "default"
    ]
};
</script>
